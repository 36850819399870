import React from 'react'
import * as Icons from './icons'
import {
  IconHeader,
  IconNav,
  IconRed,
  IconBlue,
} from '../../styles/icon.module.scss'

const Icon = (props) => {
  const { glyph, placement } = props
  const TheIcon = Icons[glyph]
  const styleMap = {
    header: IconHeader,
    nav: IconNav,
    red: IconRed,
    blue: IconBlue,
  }

  return (
    <span className={styleMap[placement]}>
      <TheIcon />
    </span>
  )
}

export default Icon
